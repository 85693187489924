@import "minima";


.wrapper {
    max-width: initial;
}

.column2 {
  float: left;
  width: 50%;
  text-align: center;
}

div.column2 p.left {
  text-align: left;
  padding-right: 2.5em;
}

.column3 {
  float: left;
  width: 33.33%;
  text-align: center;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.hero-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

div#episodelist {
	padding: 0px;
	border: 2px solid #2a7ae2;
	font-size: small;
}

ul.rss-items {
    margin: 0 0 5px !important;
}

.rss-items {
    color: #000;
    border-top: 1px solid #2a7ae2;
    margin: 0;
    padding: 0;
}

.rss-items li {
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    border-color: #2a7ae2;
    border-top-style: solid;
    border-top-width: 1px;
}

p.rss-title {
    line-height: 16px !important;
    padding: 7px!important;
    margin: 0 !important;
    background-color: #2a7ae2;

    text-decoration: none;
    font-family: verdana, sans-serif;
    font-size: 13px;
    line-height: 16px;
    color: #000 !important;
    font-weight: 700 !important;
    text-align: left;
    margin: 0;
    padding: 0;
}

.rss-box {
    background-color: #E5ECF9;
    border: 1px solid #C5D7EF;
    text-align: left;
    width: 100%;
    float: none;
    margin: 0 auto;
}

#rss {
	margin-bottom: 2em;
}

.wsite-button {
    height: 34px;
    display: inline-block;
    padding: 0px 43px;
    background: #f6b647;
    border: 3px solid #f6b647;
    text-transform: uppercase;
    font-size: 16px;
    color: #fff !important;
    transition: background 0.5s ease, border 0.5s ease;
    background-image: none !important;
}

.wsite-button-inner {
    color: #ffffff !important;
    height: 34px !important;
    line-height: 34px !important;
    display: block;
    padding: 0px !important;
    background: none !important;
}

.patreon_section {
    background-color:#2a7ae2;
    color:#ffffff;
    text-align: center;
    vertical-align: middle;
    //height: 20em;
    margin-top: 2em;
    //margin-bottom: 2em;
    padding-top: 4em;
    padding-bottom: 4em;
}

.post-content .patreon_section h2 {
    margin-top: 0px;
}

body {
    background-color: #efefef;
}

div.wrapper a.site-title {
    text-transform: uppercase;
}

.post-content h2 {
    text-align: center !important;
    margin-top: 2em;
}

.img-left {
    max-width: 20em;
    float: left;
    margin-right: 1em;
    text-align: center;
    background-color: #ffffff;
}

.img-right {
    max-width: 20em;
    float: right;
    margin-left: 1em;
    text-align: center;
    background-color: #ffffff;
}

.img-responsive {
    max-width: 100%;
    height: auto;
    width: 20em;
}
